<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    style="width:100%"
    hide-overlay
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.mf") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class=" pb-0">
        <MedicalFile @isdialog="true"></MedicalFile>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ApiService from "@/core/services/api.service";
import MedicalFile from "@/view/pages/medical_file/file.vue";
//import DoctorDashboard from "@/view/pages/dash/doctor/doctor-dashboard.vue"

export default {
  name: "mfappointement",
  props: ["dialog", "consultID"],
  components: {
    MedicalFile,
  },
  data() {
    return {};
  },
  beforeMount() {},
  watch:
  {
    dialog: function(flag)
    {
      if(flag)
      {
        this.getVia();
      }
    }
  },
  mounted() {
    
  },
  methods: {
    async getVia() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      console.log("checking via");
      let url = `md_doctor_actions`;
      if(this.consultID)
      {
        url += `?filter[referral]=${this.consultID}`
      }
      else
      {
        url += `?filter[appointment]=${this.$route.params.id}`
      }
      await ApiService.get("items",url)
        .then(({ data }) => {
          console.log(data.data);
          if (data.data.length > 0) {
            console.log("found via object and it's id is: ", data.data[0].id);
            this.$root.viaid = data.data[0].id;
          } else {
            console.log("didn't find via and should create one");
            this.setVia();
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    async setVia() {
      let payload = {};
      if(this.consultID)
      {
        payload = {
        type: 2,
        referral: this.consultID,
        medical_file: this.$root.doctormf,
      }
      }
      else
      {
        payload = {
        type: 1,
        appointment: this.$route.params.id,
        medical_file: this.$root.doctormf,
      }
      }
      await ApiService.post("items/md_doctor_actions", payload).then(({ data }) => {
        console.log(data.data);
        console.log("created via");
        this.$root.viaid = data.data.id;
      });
    },
    close() {
      console.log("clsoing");
      this.$emit("closeClicked", false);
    },
  },
};
</script>
